import React from 'react'
import './index.scss'
import Logo from './Logo/Logo'
import 'bootstrap/dist/css/bootstrap.min.css';




const Home = () => {
  return (
    <>
   <section className='home img-fluid'>
   </section>
   <Logo className='home-logo img-fluid' />
   
   
  
  </>
   
    
  )
};

export default Home